/* global DMP_JS_PROPS */

import L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import { component, store } from 'reefjs';

const dmpMap = {
  activeMarkerIcon: new L.Icon({
    iconRetinaUrl: '/static/dmp_pages/images/marker-icon-red-2x.png',
    iconUrl: '/static/dmp_pages/images/marker-icon-red.png',
    shadowUrl: '/static/dmp_pages/images/marker-shadow.png',
    // iconSize: [25, 41],
    // iconAnchor: [12, 41],
    // popupAnchor: [1, -34],
    // shadowSize: [41, 41],
  }),
  geoLayer: null,
  geoMap: null,
  locationData: store({
    locations: [],
    activeFilters: [],
  }),
  pageId: null,
  useAnimations: false,
  initMap: (
    mapEl,
    prefersReducedMotion,
    coordinates = false,
    zoom = false,
    addMarker = false,
    markerText = '',
  ) => {
    dmpMap.useAnimations = !prefersReducedMotion;
    /* eslint-disable no-underscore-dangle */
    delete L.Icon.Default.prototype._getIconUrl;
    /* eslint-enable no-underscore-dangle */
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: '/static/dmp_pages/images/marker-icon-2x.png',
      iconUrl: '/static/dmp_pages/images/marker-icon.png',
      shadowUrl: '/static/dmp_pages/images/marker-shadow.png',
    });
    // TODO get initial center of map from Wagtail settings
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
    dmpMap.geoMap = L.map(mapEl, {
      gestureHandling: true,
      fadeAnimation: dmpMap.useAnimations,
      markerZoomAnimation: dmpMap.useAnimations,
      zoomAnimation: dmpMap.useAnimations,
    });

    let mapZoom = 14;
    if (zoom !== false) {
      mapZoom = zoom;
    }

    if (coordinates !== false) {
      dmpMap.geoMap.setView(coordinates, mapZoom);
    }

    const mapAttrib =
      'Map data &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: mapAttrib,
      maxZoom: mapZoom + 4,
      minZoom: mapZoom - 4,
    }).addTo(dmpMap.geoMap);

    if (addMarker) {
      const marker = L.marker(coordinates).addTo(dmpMap.geoMap);
      marker.bindPopup(markerText).openPopup();
    }
  },
  initInteractiveMap: async (mapEl, pageId, prefersReducedMotion) => {
    // Overwrite properties for icons to change the image path
    // L.Icon.Default.prototype.options.iconUrl = '../../images/marker-icon.png';
    dmpMap.pageId = pageId;
    dmpMap.initMap(mapEl, prefersReducedMotion);

    dmpMap.getLocationData();
    dmpMap.initMapEntries();
    dmpMap.initMapFilters();
  },
  addMarkersToMap: (markers) => {
    if (dmpMap.geoLayer !== null) {
      dmpMap.geoMap.removeLayer(dmpMap.geoLayer);
    }
    dmpMap.geoLayer = L.geoJSON(markers, {
      onEachFeature: dmpMap.initiateFeature,
      pointToLayer: dmpMap.getMarkerInstance,
    });
    dmpMap.geoMap.addLayer(dmpMap.geoLayer);
  },
  clearFilters: () => {
    dmpMap.getLocationData();
    dmpMap.locationData.activeFilters = [];

    // Remove active class from all filters and entries
    const allEntries = document.querySelectorAll('.js-map-entry-button');
    for (let i = 0; i < allEntries.length; i += 1) {
      allEntries[i].classList.remove('active');
    }
    const activeFilters = document.querySelectorAll(
      '.js-map-filter-button.active',
    );
    for (let i = 0; i < activeFilters.length; i += 1) {
      activeFilters[i].classList.remove('active');
    }
  },
  focusMarker: (markerEl) => {
    const allEntries = document.querySelectorAll('.js-map-entry-button');
    for (let i = 0; i < allEntries.length; i += 1) {
      allEntries[i].classList.remove('active');
    }
    const uuid = markerEl.dataset.entry;

    if (dmpMap.geoMap !== null && dmpMap.geoLayer !== null) {
      // Find marker with UUID
      const layers = dmpMap.geoLayer.getLayers();
      for (let i = 0; i < layers.length; i += 1) {
        if (layers[i].feature.id === uuid) {
          // TODO properly focus marker and highlight it somehow (maybe different color?)
          dmpMap.geoMap.setView(layers[i].feature.geometry.coordinates, 14, {
            animate: dmpMap.useAnimations,
            padding: [50, 50],
          });
          layers[i].openPopup();
        }
      }
    }
    markerEl.classList.add('active');
  },
  getFilterTemplate: () => {
    const maxFilters = 5;
    const props = dmpMap.locationData;
    if (!props.activeFilters.length) {
      return '';
    }
    let overflowStr = '';
    const loopLength = Math.min(maxFilters, props.activeFilters.length);
    const locationOverflow = props.activeFilters.length - maxFilters;
    if (locationOverflow > 0) {
      overflowStr = ` und ${locationOverflow} weitere.`;
    }
    /* eslint-disable indent */
    return `
    <span>
        Zeigt Einträge für ${props.activeFilters
          .slice(0, loopLength)
          .map((location) => `"${location}"`)
          .join(', ')}${overflowStr}
    </span>
    <p>
        <button id="js-clear-interactive-map-filters" class="button button--danger">
            Filter zurücksetzen
        </button>
    </p>
    `;
    /* eslint-enable indent */
  },
  getLocationData: (filters = null) => {
    const id = dmpMap.pageId;
    if (id === null) {
      /* eslint-disable-next-line no-console */
      console.error('Page ID is missing.');
      return;
    }
    fetch(dmpMap.getMapUrl(id, filters), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data.success !== false) {
          dmpMap.locationData.locations = data.data.features;
          console.log('Location Data:', dmpMap.locationData.locations); // Debugging: Location-Daten anzeigen
          dmpMap.addMarkersToMap(data.data);
          dmpMap.setMapView(dmpMap.getViewBounds(data.data));
        } else {
          /* eslint-disable-next-line no-console */
          console.error('Something went wrong', data);
        }
      });
  },
  getMapUrl: (pageId, filters = null) => {
    let url = `${DMP_JS_PROPS.URLS.GETCOORDINATES}?page=${pageId}`;

    if (filters) {
      url = `${url}&category=${filters.join(',')}`;
    }
    return url;
  },
  getMarkerInstance: (geoJsonPoint, latlng) => {
    // ? maybe modify the markers a bit (different icon per category?)
    const marker = L.marker(latlng, {
      alt: geoJsonPoint.properties.title,
    });
    return marker;
  },

  getMapEntriesTemplate: () => {
    const props = dmpMap.locationData;
    if (!props.locations.length) {
        return '';
    }

    // Sortiere die Einträge alphabetisch nach dem Titel
    const sortedLocations = props.locations.sort((a, b) => {
        const titleA = a.properties.title.toLowerCase();
        const titleB = b.properties.title.toLowerCase();
        if (titleA < titleB) {
            return -1;
        }
        if (titleA > titleB) {
            return 1;
        }
        return 0;
    });

    /* eslint-disable implicit-arrow-linebreak */
    return sortedLocations
        .map(
            (location) =>
                `
                <button class="js-map-entry-button" data-entry="${location.id}">
                    <div class="frame">
                        <img src="${location.properties.image}" alt="${location.properties.title}">
                    </div>
                    <div class="padding-0-5">
                        <p class="border-b border-gray-500 mb-4">${location.properties.title}</p>
                        <p class="">${location.properties.categories}</p>
                    </div>
                </button>`,
        )
        .join('');
    /* eslint-enable implicit-arrow-linebreak */
  },


  getViewBounds: (locationData) => {
    if (!Object.keys(locationData).includes('features')) {
      /* eslint-disable-next-line no-console */
      console.error('Invalid location data format', locationData);
      return [];
    }
    const features = locationData.features;
    const coordinates = features.map((f) => f.geometry.coordinates.reverse());
    return coordinates;
  },
  highlightCurrentMarker: (e) => {
    const layer = e.target;
    // ! Here we can set the new icon, still need way to set it back to default icon though.
    // layer.setIcon(dmpMap.activeMarkerIcon);
    // layer.openPopup();
    const uuid = layer.feature.id;
    const allEntries = document.querySelectorAll('.js-map-entry-button');
    const entryOfMarker = document.querySelector(
      `.js-map-entry-button[data-entry="${uuid}"]`,
    );

    for (let i = 0; i < allEntries.length; i += 1) {
      allEntries[i].classList.remove('active');
    }
    if (entryOfMarker) {
      entryOfMarker.classList.add('active');
      entryOfMarker.scrollIntoView(false);
    }
  },
  initiateFeature: (feature, layer) => {
    if (feature.properties && feature.properties.title) {
      // layer.bindPopup(feature.properties.title);
      layer.bindPopup(
        `<div class="stack stack--dense min-inline-size-150">
          <span class="font-weight-700">${feature.properties.title}</span>
          <span>${feature.properties.street}</span>
          <span>${feature.properties.zipCode} ${feature.properties.city}</span>
          <a href="${feature.properties.url}">Zur Seite</a>
        </div>`,
      );
    }
    layer.on({
      click: dmpMap.highlightCurrentMarker,
    });
  },


  /**
   * Filters and displays the map entries based on the search query.
   * @param {string} query - The search query to filter entries.
   */
  filterMapEntries: (query) => {
    const props = dmpMap.locationData;
    if (!props.locations.length) return;

    // Filter locations based on the query
    const filteredLocations = props.locations.filter((location) => {
      const titleMatch = location.properties.title.toLowerCase().includes(query.toLowerCase());
      const categoriesMatch = location.properties.categories
        .map(category => category.toLowerCase())
        .join(' ')
        .includes(query.toLowerCase());
      return titleMatch || categoriesMatch;
    });

    // Update the entries displayed
    dmpMap.updateMapEntriesTemplate(filteredLocations);
  },

  /**
   * Updates the map entries template with the provided locations.
   * @param {Array} locations - The filtered locations to display.
   */
    updateMapEntriesTemplate: (locations) => {
      const mapEntries = document.querySelector('#js-map-entries');
      if (mapEntries) {
        if (locations.length === 0) {
          // Display a message when no results are found
          mapEntries.innerHTML = `
            <button class="js-map-entry-button no-results">
              <div class="padding-0-5">
                <p class="border-b border-gray-500 mb-4">Keine Treffer</p>
              </div>
            </button>
          `;
        } else {
          // Render entries for filtered locations
          mapEntries.innerHTML = locations
            .sort((a, b) => a.properties.title.localeCompare(b.properties.title))
            .map(
              (location) => `
                <button class="js-map-entry-button" data-entry="${location.id}">
                    <div class="frame">
                        <img src="${location.properties.image}" alt="${location.properties.title}">
                    </div>
                    <div class="padding-0-5">
                        <p class="border-b border-gray-500 mb-4">${location.properties.title}</p>
                        <p class="">${location.properties.categories.join(', ')}</p>
                    </div>
                </button>
              `
            )
            .join('');
        }
      }
    },

  // Attach the search functionality to input event
  attachSearchListener: () => {
    const searchInput = document.querySelector('#js-map-search');
    if (searchInput) {
      searchInput.addEventListener('input', (event) => {
        const query = event.target.value;
        dmpMap.filterMapEntries(query);
      });
    }
  },

  initMapEntries: () => {
    const mapEntries = document.querySelector('#js-map-entries');
    if (mapEntries !== null) {
      component(mapEntries, dmpMap.getMapEntriesTemplate);
    }
    // Initialize the search input after entries are loaded
    dmpMap.attachSearchListener();
  },

  initMapFilters: () => {
    const mapFilters = document.querySelector('#js-current-filters');
    if (mapFilters !== null) {
      component(mapFilters, dmpMap.getFilterTemplate);
    }
  },
  setMapView: (viewBounds) => {
    if (dmpMap.geoMap !== null) {
      const viewOptions = {
        animate: dmpMap.useAnimations,
        maxZoom: 16,
        minZoom: 14,
        padding: [-50, -50],
      };
      // Check for `prefer-reduced-motion` setting and only animate if allowed.
      if (dmpMap.useAnimations) {
        try {
          dmpMap.geoMap.flyToBounds(viewBounds, viewOptions);
        } catch (error) {
          // If no center has been set yet, which can happen for interactive
          // maps, flyToBounds will throw an error to set the center and zoom
          // for the maps first. fitBounds takes care of that, so all
          // subsequent calls should work fine with flyToBounds.
          dmpMap.geoMap.fitBounds(viewBounds, viewOptions);
        }
      } else {
        dmpMap.geoMap.fitBounds(viewBounds, viewOptions);
      }
    }
  },
  updateMapMarkers: (buttonEl) => {
    const activeFilterSlugs = [];
    const activeFiltersTitles = [];
    let activeFilters = document.querySelectorAll(
      '.js-map-filter-button.active',
    );

    if (buttonEl.classList.contains('active')) {
      buttonEl.classList.remove('active');
      buttonEl.blur();
    } else {
      // This for loop ensures only one filter can be active at a time
      for (let i = 0; i < activeFilters.length; i += 1) {
        activeFilters[i].classList.remove('active');
      }
      buttonEl.classList.add('active');
    }

    activeFilters = document.querySelectorAll('.js-map-filter-button.active');

    if (activeFilters.length) {
      // If there is at least one active filter, get all active filter slugs
      // and append it to the coordinates URL as GET parameter.
      for (let i = 0; i < activeFilters.length; i += 1) {
        activeFiltersTitles.push(activeFilters[i].textContent.trim());
        activeFilterSlugs.push(activeFilters[i].dataset.filter);
      }
      dmpMap.getLocationData(activeFilterSlugs);
      dmpMap.locationData.activeFilters = activeFiltersTitles;
    } else {
      // Otherwise (no active filter) show all markers by default.
      dmpMap.getLocationData();
      dmpMap.locationData.activeFilters = [];
    }
  },
};

export default dmpMap;
